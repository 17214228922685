:root {
  --app-max-width: 1200px;
  --header-padding: 500px;
  --header-height: 52px;
  --bg-color: black;
}

h2 {
  font-family: "Light";
}

/* Font styles */

.Text-Bold {
  font-family: "Bold";
}

/* App Content */

.App {
  margin: auto;
  font-family: "Regular";
}

.App-Container {
  background-color: whitesmoke;
}

.Home-Page-Overlay {
  max-width: 1200px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background: rgba(12, 0, 0, 0.5);
  border-radius: 10px;
  width: max-content;
  font-size: 50px;
  font-family: "Medium";
}

.Hero-Image-Container {
  /* max-height: var(--header-padding); /* uncomment to have a static height for the hero image to grow/shrink into*/
  overflow: hidden;
  width: 100%;
  height: auto;
  min-height: var(--header-height);
  max-width: var(--app-max-width);
  margin: auto;
}

.Hero-Image {
  max-width: 100%; 
  display: block;
}

.Hero-Image-Text {
  width: 100%; 
  margin: auto;
}

.Section {
  padding: 30px;
  margin: 30px auto;
  width: 90%;
  background-color: none;
  border-bottom: 1px solid grey;
}

.Section-Content-Container {
  display: flex;
}

.Sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.Body-Container {
  max-width: 1200px;
  margin: auto;
  background-color: grey;
}

.Body {
  padding: 24px;
  background-color: floralwhite;
  min-height: 100vh;
}

.Opacity-Hidden {
  opacity: 0 !important;
}

.Opacity-Shown {
  opacity: 1 !important;
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.Grid-Hover-Text {
  position: absolute;
  text-align: center;
  width: inherit;
  height: inherit;
  background: rgba(0, 0, 0, 0.5); 
  color: white;
  z-index: 2;
  text-align: center;
  opacity: 0;
  transition: all 0.3s
}

.Grid-Hover-Text > p {
  position: relative;
  margin: auto;
  top: 46%;
  font-family: "Bold";
}

.Grid-Square {
  width: 200px;
  height: 200px;
  align-items: center;
  text-align: center;
  margin: 20px;
  background-color: black;
  border: 4px solid black;
  transition: all .2s ease-in-out; 
}

.Grid-Square:hover {
  transform: scale(1.2); 
  z-index: 0;
}

.Grid-Square img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.Grid-Square .FullImg {
  padding: 0;
}

.Grid-Square .FullImg img {
  object-fit: cover;
} 

/* Responsive styling - media queries */
@media only screen and (max-width: 1024px) /* for ipad */ {
  .Home-Page-Overlay {
    font-size: 36px;
  }  
}

@media only screen and (max-width: 640px) /* for phone */ {
  .Home-Page-Overlay {
    font-size: 24px;
    top: 15%;
  }
  .Section-Content-Container {
    display: block;
  }
}


 

/* Font Faces */

@font-face {
  font-family: "Light";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";   
  src: local("Montserrat"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraLight";
  src: local("Montserrat"),
    url("./fonts/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Thin";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
}

/* Header bar styles + hamburger menu */

.Header {
  position: fixed;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  color: whitesmoke;
  background-color: black;
  padding: 10px 42px;
  height: var(--header-height);
  z-index: 10;
}

.Header-Logo {
  margin-right: 10px;
  height: inherit;
  cursor: pointer;
}

.Header-Link {
  font-size: 18px;
  color: whitesmoke;
  padding: 5px 24px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.Link {
  text-decoration: none;
}

.Menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.Menu-Button-Container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

#Menu-Toggle {
  display: none;
}

.Menu-Button,
.Menu-Button::before,
.Menu-Button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.Menu-Button::before {
  content: "";
  margin-top: -8px;
}

.Menu-Button::after {
  content: "";
  margin-top: 8px;
}

#Menu-Toggle:checked + .Menu-Button-Container .Menu-Button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#Menu-Toggle:checked + .Menu-Button-Container .Menu-Button {
  background: rgba(255, 255, 255, 0);
}

#Menu-Toggle:checked + .Menu-Button-Container .Menu-Button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (min-width: 640px) {
  .Header-Link::after {
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 10%;
    background-color: whitesmoke;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .Header-Link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .Menu > .Header-Link {
    display: none;
  }
}

@media (max-width: 700px) {
  .Header-Link {
    display: none;
  }
  .Header-Link::after {
    background-color: transparent;
  }
  .Header-Link:hover::after {
    transform: none;
  }

  .Menu-Button-Container {
    display: flex;
  }

  .Menu {
    position: absolute;
    top: 0;
    margin-top: 72px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #Menu-Toggle ~ .Menu .Header-Link {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    visibility: hidden;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #Menu-Toggle:checked ~ .Menu .Header-Link {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    visibility: visible;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .Menu > .Header-Link {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
    align-items: center;
  }

  .Menu > .Header-Link:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}